/*Create by Sanj*/
import axios from "axios";
const login = (userObj) => {
  return new Promise((resolve, reject) => {
    axios 
      // .post("admin/admin_login", userObj)
      .post("/api/v1/admin/login", userObj)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const logout = () => {
  return new Promise((resolve, reject) => {
  axios.post('api/v1/logout')
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const Auth = {
  login,
  logout,
};

export default Auth;