<template>
  <div class="bgwhite">
    <div class="box-header">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <!-- <a class="text-primary mb-4" href="/manage">← Enterprises</a> -->
             <router-link to="/manage" style="text-decoration: none; color: #575757">
              ← Enterprises
            </router-link>
            <h4 class="box-title m-0 fs-20 fw-5">Summary</h4>
          </div>

          <div class="d-flex justify-content-end col-md-6">
            <div class="text-end show dropdown">
              <button
                @click="openDrop()"
                type="button"
                class="dropdown-toggle btn btn-primary"
              >
                Options
              </button>
              <div v-if="isHide" class="dropdown-menu show dropdown-menu-end">
                <router-link class="dropdown-item" :to="{ path: '/edit-organization/'+id}" >Edit Enterprises</router-link>
                <router-link class="dropdown-item" :to="{ path: '/add-user/'+id}" >Add User</router-link>

                <!-- <a class="dropdown-item" @click="removeUser">Remove Enterprises</a> -->
              <!--   <hr class="dropdown-divider" />
                <a class="dropdown-item" href="#">Add App Instances</a> -->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="bg-white d-flex main-box">
      <div class="left-tabs">
        <ul>
          <li
            :class="{ active: activeTab === 1 }"
            @click="selectConnectedAppdata(1)"
          >
            Enterprise Information
          </li>
          <li
            :class="{ active: activeTab === 2 }"
            @click="selectConnectedAppdata(2)"
          >
            User List
          </li>
          <li
            :class="{ active: activeTab === 3 }"
            @click="selectConnectedAppdata(3)"
          >
            Activity Log
          </li>
        </ul>
      </div>
      <div class="right-tabs vertical-scroll">
        <EnterprisesInfo v-if="selectedTab == 1" :id="id" />
        <UserTable v-if="selectedTab == 2" :id="id" />
        <ActivityLog v-if="selectedTab == 3" :id="id" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EnterprisesInfo from "../EntComponents/EnterprisesInfo.vue";
import UserTable from "../EntComponents/UserTable.vue";
import ActivityLog from "../EntComponents/ActivityLog.vue";

export default {
  props: {
    id: Number,
    default:0,
  },
  name: "UserView",
  components: {
    EnterprisesInfo,
    UserTable,
    ActivityLog,
  },
  data() {
    return {
      selectedTab: 1,
      activeTab: 1,
      isHide: false,
    };
  },

  methods: {
    selectConnectedAppdata(val) {
      this.selectedTab = val;
      this.activeTab = val;
      this.activeLog = val;
    },
    hide() {
      this.isHide = false;
    },
    openDrop() {
      // this.isHide = true;
      if(this.isHide == true){
        this.isHide = false;
      }else{  
        this.isHide = true;
      }
    },

    removeUser() {
      let self = this;
      console.log("create-delete-api"+this.id);
    },
  },
};
</script>
<style>
@import url("../../assets/styles/themes/_default.scss");
</style>
