import axios from "axios";
//axios.defaults.baseURL = "https://test.api.gozwing.com/";
//axios.defaults.baseURL = "http://localhost/zwing-admin-api/public/";
 //axios.defaults.baseURL = "http://localhost/zwing/zwing-admin-api/public/";
//axios.defaults.baseURL = "http://localhost/zwing-admin-api/public/";

axios.defaults.baseURL = "https://test.onboarding.api.gozwing.com/public/";

let token = localStorage.getItem('session') === null?'':JSON.parse(localStorage.getItem('session')).token;
token = localStorage.getItem('session') === null?'':JSON.parse(localStorage.getItem('session')).token;

axios.defaults.headers["Authorization"] = 'Bearer '+token;

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";


export default axios;
