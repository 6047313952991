import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';

createApp(App).use(router).use(store).use(router).mount("#app");

require("../src/assets/styles/style.scss");

