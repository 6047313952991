import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import store from "../store/index";
import OrganizationList from "../components/Organization/OrganizationList.vue";
import AddOrganization from "../components/Organization/AddOrganization.vue";
import OrganizationView from "../components/Organization/OrganizationView";
import UserList from "../components/SuperUser/SuperUserList.vue";
import AddUser from "../components/SuperUser/AddUser.vue";
import UserView from "../components/SuperUser/UserView.vue";
import UserSummary from "../components/Organization/UserSummary/UserSummary.vue";
import UserInfo from "../components/Organization/UserSummary/UserInformation.vue";
import EditUser from "../components/Organization/UserSummary/EditUser.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
/* {
  path: "/dashboard",
  name: "dashboard",
  component: OrganizationList,
  meta: {
      requiresAuth: true,
  }
},*/
  {
    path: "/manage",
    name: "OrganizationList",
    component: OrganizationList,
    meta: {
        requiresAuth: true,
    }
  },
  {
    path: "/add-organization",
    name: "AddOrganization",
    component: AddOrganization,
  },
  {
    path: "/view-organization/:id",
    name: "OrganizationView",
    component: OrganizationView,
    props: true,
  },
  {
    path: "/edit-organization/:id",
    name: "OrganizationEdit",
    component: AddOrganization,
    props: true,
  },
  {
    path: "/user-list",
    name: "UserList",
    component: UserList,
  },
  {
    path: "/add-user/:id",
    name: "AddUser",
    component: AddUser,
  },
  {
    path: "/user-view",
    name: "UserView",
    component: UserView,
  },
  {
    path: "/user-summary/:id",
    name: "UserSummary",
    component: UserSummary,
    props: true,
  },
  {
    path: "/user-info",
    name: "UserInfo",
    component: UserInfo,
  },
  {
    path: "/edit-user/:id",
    name: "EditUser",
    component: EditUser,
  },

];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "register") {
    if (store.state.system.licence == "") {
      next();
    } else {
      next("/");
    }
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
      console.log('--------ddd--------------'+JSON.stringify(store.getters.user))

    if (store.getters.isLoggedIn) {

      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
