<template>
  <div class="basic-info p-32">
    <div>
      <h6 class="fs-20 fw-5 box-title">Basic Details</h6>
      <div class="mb-5 col-md-12">
        <div class="d-flex align-items-center row">
          <div class="col-md-8">
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Organization</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.name }}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Legal Name</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.legal_name }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Email</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.email }}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Phone</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.phone }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Alternate Phone</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.phone_2 }}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Status</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.status == 1 ? "Active":"InActive" }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h6 class="fs-20 fw-5 box-title">Registered Address</h6>
      <div class="mb-5 col-md-12">
        <div class="d-flex align-items-center row">
          <div class="col-md-8">
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Address</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.registered_address }}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Pincode</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.registered_pincode }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Country</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.reg_country_name }}</p>
              </div>

              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">State</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.reg_state_name }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">City</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.registered_city_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h6 class="fs-20 fw-5 box-title">Corporate Address</h6>
      <div class="mb-5 col-md-12">
        <div class="d-flex align-items-center row">
          <div class="col-md-8">
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Address</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.address }}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Pincode</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.pincode }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Country</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.country_name }}</p>
              </div>

              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">State</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.state_name }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">City</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.city_name }}</p>
              </div>

              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Time Zone</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.time_zone_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
    <h6 class="fs-20 fw-5 box-title">Vendor Setting</h6>
     <div class="mb-4 row">
      <div class="text-start col-md-6">
        <label class="grey-60 fs-12 mb-1">Allow user</label>
        <p class="m-0 fs-14 text-black">{{ orgDetails.allow_user }}</p>
      </div>
      <div class="text-start col-md-6">
        <label class="grey-60 fs-12 mb-1">Allow Store</label>
        <p class="m-0 fs-14 text-black">{{ orgDetails.allow_store }}</p>
      </div>
    </div>
    </div>

    <div>
      <h6 class="fs-20 fw-5 box-title">Document details</h6>
      <div class="mb-5 col-md-12">
        <div class="d-flex align-items-center row">
          <div class="col-md-8">
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Pan Number</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.pan_no }}</p>
              </div>
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">GST Number</label>
                <p class="m-0 fs-14 text-black">{{ orgDetails.gst_no }}</p>
              </div>
            </div>
            <div class="mb-4 row">
              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">Pan Image</label>
                <p class="m-0 fs-14 text-black"></p>
              </div>

              <div class="text-start col-md-6">
                <label class="grey-60 fs-12 mb-1">GST Image</label>
                <p class="m-0 fs-14 text-black"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h6 class="fs-20 fw-5 box-title">Images {{ orgDetails.gst_image }} </h6>
      <div class="mb-5 col-md-12">
        <div class="d-flex align-items-center row">
          <div class="col-md-8">
            <div class="mb-4 row">
              <div class="text-start col-md-2">
                <img
                  src="../../assets/img/logo-full.svg"
                  class="img-thumbnail"
                />
              </div>
              <div class="text-start col-md-2">
                <img
                  src="../../assets/img/logo-full.svg"
                  class="img-thumbnail"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    id: Number,
  },

  name: "EnterprisesInfo",
  components: {},

  data() {
    return {
      orgDetails:[],
    };
  },

  mounted: function () {
    this.getOrgDetail();
  },

  methods: {
    getOrgDetail() {
      let self = this;
      self.$loading = true;
      axios.get("api/v1/vendor/details/"+ self.id)
        .then(function (response) {
          self.orgDetails = response.data.data;
        })
        .catch(function (error) {
          self.$loading = false;
        });
    },
  },
};
</script>
